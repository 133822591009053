import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloud, faBox, faPlug, faStar, faMicrochip } from "@fortawesome/free-solid-svg-icons"

const DeploymentOptions = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <span className="badge badge-pill badge-success-soft mb-3">
            <span className="h6 text-uppercase">Products &amp; Solutions</span>
          </span>

          <h2 className="font-weight-bold">
            Anonymization <br />
            <span className="text-success">where you need it.</span>
          </h2>

          <p className="font-size-lg text-gray-700">
            Different projects have different requirements. Choose a solution that fits your need.
          </p>
        </div>

        <div class="list-group col-12 col-md-6 shadow-lift p-0">
          <Link to="/container/" className="list-group-item list-group-item-action d-flex align-items-center">
            <div className="mr-auto">
              <p className="font-weight-bold mb-1">Container</p>
              <p className="font-size-sm text-muted mb-0">
                Deploy the Celantur Docker container easily in your public/private cloud or on-premise. Maximum
                scalability.
              </p>
            </div>
            <FontAwesomeIcon icon={faBox} size="2x" style={{ color: "#42ba96" }} className="ml-3" />
          </Link>
          <Link to="/api/" className="list-group-item list-group-item-action d-flex align-items-center">
            <div className="mr-auto">
              <p className="font-weight-bold mb-1">Cloud API</p>
              <p className="font-size-sm text-muted mb-0">Anonymize images via simple REST API calls. Data processing in the EU.</p>
            </div>
            <FontAwesomeIcon icon={faPlug} size="2x" style={{ color: "#42ba96" }} className="ml-3" />
          </Link>
          <a href="https://app.celantur.com/" target="_blank" className="list-group-item list-group-item-action d-flex align-items-center">
            <div className="mr-auto">
              <p className="font-weight-bold mb-1">App</p>
              <p className="font-size-sm text-muted mb-0">
                Easy-to-use, browser-based SaaS solution. Data processing in the EU.
              </p>
            </div>
            <FontAwesomeIcon icon={faCloud} size="2x" style={{ color: "#42ba96" }} className="ml-3" />
          </a>
          <Link to="/edge/" className="list-group-item list-group-item-action d-flex align-items-center event-contact">
            <div className="mr-auto">
              <p className="font-weight-bold mb-1">Edge / SDK</p>
              <p className="font-size-sm text-muted mb-0">
                SDK for edge/embedded systems, desktop software, etc.
              </p>
            </div>
            <FontAwesomeIcon icon={faMicrochip} size="2x" style={{ color: "#42ba96" }} className="ml-3" />
          </Link>
        </div>
      </div>
    </>
  )
}

export default DeploymentOptions
